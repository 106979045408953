<template>
    <div id="signin-form" :class="{signinFormActive: active}" :style="{ top: top, left: left}">
        <p class="err-msg">
            {{signinMessage}}
        </p>
        <div class="text">
            <p><input autofocus type="text" v-model="username" placeholder="Enter the username"></p>
            <p><input type="password" v-model="password" placeholder="Enter the password" @keyup.enter="signin"></p>
        </div>        
        <div class="buttons">
            <button @click="signin">Signin</button>
        </div>
        <button id="close-button" @click="close">&#10005;</button>
    </div>
</template>
<script>
// import Singleton from '../js/singleton';
import Singleton from '../singleton';
export default {
    data(){
        return {
            username: 'admin',
            password: 'admin',
        }
    },
    methods:{
        close(){
            this.$store.commit('signinFormActive', false);
            this.username ='';
            this.password ='';
            this.$store.state.singleton.resetSession();
        },
        onResponse(response){            
            if (!response.isSuccess()){
                this.$store.commit('signinMessage', "Invalid credentials"); 
            } 
            else{
                this.$store.commit('signinMessage', "Signed in"); 
                this.$store.commit('signinFormActive', false);
                this.$store.commit('userId', Singleton.userId);
            }            
        },
        signin(){
            const singleton = this.$store.state.singleton;            
            singleton.test();
            if ((this.username.length > 0) && (this.password.length > 0)){                
                const jsonObject = {  
                    "actionId" : "1", //SIGNIN
                    "username" : this.username,
                    "password" : this.password  
                }
                this.$store.commit('signinMessage', "Signing in"); 
                singleton.send(jsonObject, this.onResponse, this.onClose);
                this.username = '';
                this.password = '';                      
            }else{
                this.$store.commit('signinMessage', "Please enter the credentials"); 
            }
        },
        onClose(){
            this.$store.commit('userId', 0);
            this.$store.state.singleton.resetSession();
        }
    },
    computed: {        
        active(){
            return this.$store.state.signinFormActive;
        },
        top(){
            return `${this.$store.state.eventFormPosY}px`;
        },
        left(){
            return `${this.$store.state.eventFormPosX - 250}px`;
        },
        signinMessage(){
            return this.$store.state.signinMessage;
        }
    },
    directives: {        
        focus:{
            update(el){                
                el.focus();
            }
        }
    }
}
</script>

<style scoped>
    #signin-form{
        display: none;
        box-shadow: 0 2px 4px #ddd;
        width: 250px;
        max-width: 90%;
        z-index: 10;
        padding: 2px;
        background-color: white;
        border: 1px  #ddd solid;
        color: black;
    } 

    .signinFormActive{
        position: absolute;
        display: block !important;
        z-index: 10;
    }

    #signin-form .buttons{
        text-align: left;
    }
    #signin-form .err-msg{
        position: relative;
        padding: 0;
        margin: 0;
        color: black;
        font-size: 10px;
        color: red;
    }
    #signin-form .text p{
        padding: 2px;
    }
</style>